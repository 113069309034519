import React, { useEffect, useState, useContext, Fragment } from "react";

const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
let hours = [];
for (let i = 0; i <= 23; i++) {
  hours.push(i);
}
//"background-color:green;border: gainsboro 1px solid;background: linear-gradient(180deg, #FFF 50%, green 50%);"
const AvailabilityTable = ({ availability }) => (
  <div style={{overflow:'auto'}}>
    <table>
      <thead>
        <tr>
          <th> </th>
          {hours.map((hour) => (
            <th key={hour}>
              <center>{(hour < 10 ? "0" : "") + hour}</center>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {daysOfWeek.map((day) => (
          <tr key={day}>
            <td>{day}</td>
            {hours.map((hour) => {
              let strHour = (hour < 10 ? "0" : "") + hour + ":00";
              let strHalfHour = (hour < 10 ? "0" : "") + hour + ":30";
              let bg = { border: "gainsboro 1px solid", width: "40px" };

              if (
                availability[day].indexOf(strHour) !== -1 &&
                availability[day].indexOf(strHalfHour) !== -1
              ) {
                bg = { ...bg, backgroundColor: "green" };
              } else if (availability[day].indexOf(strHour) !== -1) {
                bg = { ...bg, background: "linear-gradient(90deg, green 50%, white 50%)" };
              } else if (availability[day].indexOf(strHalfHour) !== -1) {
                bg = {
                  ...bg,
                  background: "linear-gradient(90deg, white 50%, green 50%)",
                };
              }

              return <td key={day + hour} style={bg}></td>;
            })}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default AvailabilityTable;
