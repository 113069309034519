import React, { useEffect, useState } from 'react';

import UsersList from './components/UsersList';
import ErrorSnack from "../shared/components/UIElements/ErrorSnack";
import LoadingSpinner from '../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../shared/hooks/http-hook';

import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";

import Auth from "./Auth";

const Home = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();


  return (
    <React.Fragment>
      <ErrorSnack error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && (
        <React.Fragment>
          <CssBaseline />
          <Container maxWidth="xl">{!props.isLoggedIn && <Auth />}</Container>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Home;
