import axios from "axios";

export const deleteTutor = (id) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_TUTORS", payload: true });
    dispatch({ type: "SET_BYPASS_IO_TUTORS", payload: true });
    try {
      const responseData = await axios.delete(process.env.REACT_APP_BACKEND_URL + "/tutors/" + id);
      dispatch({ type: "DELETE_TUTOR", payload: id });
      dispatch({ type: "LOADING_TUTORS", payload: false });
      setInterval(() => dispatch({ type: "SET_BYPASS_IO_TUTORS", payload: false }), 1000);
    } catch (err) {
      dispatch({ type: "ERROR_TUTORS", payload: err });
      dispatch({ type: "SET_BYPASS_IO_TUTORS", payload: false });
      dispatch({ type: "LOADING_TUTORS", payload: false });
    }
  };
};

export const setBypassIoTutors = (should) => ({ type: "SET_BYPASS_IO_TUTORS", payload: should });

export const setOpenTutor = (open) => ({ type: "SET_OPEN_TUTOR", payload: open });

export const updateTutor = (tutor) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_TUTORS", payload: true });
    //dispatch({ type: "SET_BYPASS_IO_TUTORS", payload: true });
    try {
      console.log(tutor);
      const responseData = await axios.post(process.env.REACT_APP_BACKEND_URL + "/tutors", tutor);
      dispatch({ type: "UPDATE_TUTOR", payload: tutor });
      dispatch({ type: "LOADING_TUTORS", payload: false });
      dispatch({ type: "CLEAR_CURRENT_TUTOR" });
      //setInterval(()=>dispatch({ type: "SET_BYPASS_IO_TUTORS", payload: false }),1000);
    } catch (err) {
      dispatch({ type: "ERROR_TUTORS", payload: err });
      //dispatch({ type: "SET_BYPASS_IO_TUTORS", payload: false });
      dispatch({ type: "LOADING_TUTORS", payload: false });
    }
  };
};

export const addTutor = (tutor) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_TUTORS", payload: true });
    //dispatch({ type: "SET_BYPASS_IO_TUTORS", payload: true });
    try {
      const responseData = await axios.put(process.env.REACT_APP_BACKEND_URL + "/tutors", tutor);
      dispatch({ type: "ADD_TUTOR", payload: tutor });
      dispatch({ type: "LOADING_TUTORS", payload: false });
      dispatch({ type: "SET_OPEN_TUTOR", payload: false });
      //setInterval(() => dispatch({ type: "SET_BYPASS_IO_TUTORS", payload: false }), 1000);
    } catch (err) {
      dispatch({ type: "ERROR_TUTORS", payload: err });
      //dispatch({ type: "SET_BYPASS_IO_TUTORS", payload: false });
      dispatch({ type: "LOADING_TUTORS", payload: false });
    }
  };
};

export const setCurrentTutor = (tutor) => ({ type: "CURRENT_TUTOR", payload: tutor });

export const clearCurrentTutor = () => ({ type: "CLEAR_CURRENT_TUTOR" });

export const clearErrorTutors = () => ({ type: "CLEAR_ERROR_TUTORS" });

export const getTutors = (filter) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_TUTORS", payload: true });
    if (filter) dispatch({ type: "FILTER_TUTORS", payload: filter });

    try {
      
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/tutors/All"
        //+(filter && filter.status ? filter.status : "All")
      );

      dispatch({ type: "GET_TUTORS", payload: responseData.data.tutors });
      dispatch({ type: "LOADING_TUTORS", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_TUTORS", payload: err });
      dispatch({ type: "LOADING_TUTORS", payload: false });
    }
  };
};
