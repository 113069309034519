import React, { useEffect, useState } from "react";


import ErrorSnack from "../shared/components/UIElements/ErrorSnack";
import LoadingSpinner from "../shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../shared/hooks/http-hook";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";

import {Icon} from "@material-ui/core"

const UserRoles = (props) => {
  const classes = useStyles();

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedRoles, setLoadedRoles] = useState();

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/users/userRoles"
        );

        setLoadedRoles(responseData.roles);
      } catch (err) {}
    };
    fetchRoles();
  }, [sendRequest]);

  return (
    <React.Fragment>
      <ErrorSnack error={error} onClear={clearError} />
      {isLoading && (
        <Container maxWidth="xl">
          <CssBaseline />
          <div className="center">
            <LoadingSpinner />
          </div>
        </Container>
      )}
      {!isLoading && loadedRoles && (
        <React.Fragment>
          <CssBaseline />
          <Container maxWidth="md">
            <h1>User Roles</h1>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loadedRoles.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>

                      <TableCell align="right">
                        <Icon>delete</Icon>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default UserRoles;
