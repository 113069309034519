const languages = [
  { code: "ab", name: "Abkhazian", sort: 1000 },
  { code: "aa", name: "Afar", sort: 1000 },
  { code: "af", name: "Afrikaans", sort: 1000 },
  { code: "ak", name: "Akan", sort: 1000 },
  { code: "sq", name: "Albanian", sort: 1000 },
  { code: "am", name: "Amharic", sort: 1000 },
  { code: "ar", name: "Arabic", sort: 1000 },

  { code: "an", name: "Aragonese", sort: 1000 },
  { code: "hy", name: "Armenian", sort: 1000 },
  { code: "as", name: "Assamese", sort: 1000 },
  { code: "av", name: "Avaric", sort: 1000 },
  { code: "ae", name: "Avestan", sort: 1000 },
  { code: "ay", name: "Aymara", sort: 1000 },
  { code: "az", name: "Azerbaijani", sort: 1000 },
  { code: "bm", name: "Bambara", sort: 1000 },
  { code: "ba", name: "Bashkir", sort: 1000 },
  { code: "eu", name: "Basque", sort: 1000 },
  { code: "be", name: "Belarusian", sort: 1000 },
  { code: "bn", name: "Bengali", sort: 1000 },
  { code: "bh", name: "Bihari languages", sort: 1000 },
  { code: "bi", name: "Bislama", sort: 1000 },
  { code: "bs", name: "Bosnian", sort: 1000 },
  { code: "br", name: "Breton", sort: 1000 },
  { code: "bg", name: "Bulgarian", sort: 1000 },
  { code: "my", name: "Burmese", sort: 1000 },
  { code: "ca", name: "Catalan, Valencian", sort: 1000 },
  { code: "km", name: "Central Khmer", sort: 1000 },
  { code: "ch", name: "Chamorro", sort: 1000 },
  { code: "ce", name: "Chechen", sort: 1000 },
  { code: "ny", name: "Chichewa, Chewa, Nyanja", sort: 1000 },
  { code: "zh", name: "Chinese", sort: 30 },

  { code: "cv", name: "Chuvash", sort: 1000 },
  { code: "kw", name: "Cornish", sort: 1000 },
  { code: "co", name: "Corsican", sort: 1000 },
  { code: "cr", name: "Cree", sort: 1000 },
  { code: "hr", name: "Croatian", sort: 1000 },
  { code: "cs", name: "Czech", sort: 1000 },
  { code: "da", name: "Danish", sort: 1000 },
  { code: "dv", name: "Divehi, Dhivehi, Maldivian", sort: 1000 },
  { code: "nl", name: "Dutch, Flemish", sort: 1000 },
  { code: "dz", name: "Dzongkha", sort: 1000 },
  { code: "en", name: "English", sort: 1 },
  { code: "eo", name: "Esperanto", sort: 1000 },
  { code: "et", name: "Estonian", sort: 1000 },
  { code: "ee", name: "Ewe", sort: 1000 },
  { code: "fo", name: "Faroese", sort: 1000 },
  { code: "fj", name: "Fijian", sort: 1000 },
  { code: "fi", name: "Finnish", sort: 1000 },
  { code: "fr", name: "French", sort: 70 },
  { code: "ff", name: "Fulah", sort: 1000 },
  { code: "gd", name: "Gaelic, Scottish Gaelic", sort: 1000 },
  { code: "gl", name: "Galician", sort: 1000 },
  { code: "lg", name: "Ganda", sort: 1000 },
  { code: "ka", name: "Georgian", sort: 1000 },
  { code: "de", name: "German", sort: 40 },
  { code: "ki", name: "Gikuyu, Kikuyu", sort: 1000 },
  { code: "el", name: "Greek (Modern)", sort: 1000 },
  { code: "kl", name: "Greenlandic, Kalaallisut", sort: 1000 },
  { code: "gn", name: "Guarani", sort: 1000 },
  { code: "gu", name: "Gujarati", sort: 1000 },
  { code: "ht", name: "Haitian, Haitian Creole", sort: 1000 },
  { code: "ha", name: "Hausa", sort: 1000 },
  { code: "he", name: "Hebrew", sort: 90 },
  { code: "hz", name: "Herero", sort: 1000 },
  { code: "hi", name: "Hindi", sort: 1000 },
  { code: "ho", name: "Hiri Motu", sort: 1000 },
  { code: "hu", name: "Hungarian", sort: 1000 },
  { code: "is", name: "Icelandic", sort: 1000 },
  { code: "io", name: "Ido", sort: 1000 },
  { code: "ig", name: "Igbo", sort: 1000 },
  { code: "id", name: "Indonesian", sort: 1000 },

  { code: "iu", name: "Inuktitut", sort: 1000 },
  { code: "ik", name: "Inupiaq", sort: 1000 },
  { code: "ga", name: "Irish", sort: 1000 },
  { code: "it", name: "Italian", sort: 60 },
  { code: "ja", name: "Japanese", sort: 1000 },

  { code: "jv", name: "Javanese", sort: 1000 },
  { code: "kn", name: "Kannada", sort: 1000 },
  { code: "kr", name: "Kanuri", sort: 1000 },
  { code: "ks", name: "Kashmiri", sort: 1000 },
  { code: "kk", name: "Kazakh", sort: 1000 },
  { code: "rw", name: "Kinyarwanda", sort: 1000 },
  { code: "kv", name: "Komi", sort: 1000 },
  { code: "kg", name: "Kongo", sort: 1000 },
  { code: "ko", name: "Korean", sort: 80 },
  { code: "kj", name: "Kwanyama, Kuanyama", sort: 1000 },
  { code: "ku", name: "Kurdish", sort: 1000 },
  { code: "ky", name: "Kyrgyz", sort: 1000 },
  { code: "lo", name: "Lao", sort: 1000 },
  { code: "la", name: "Latin", sort: 1000 },
  { code: "lv", name: "Latvian", sort: 1000 },
  { code: "lb", name: "Letzeburgesch, Luxembourgish", sort: 1000 },
  { code: "li", name: "Limburgish, Limburgan, Limburger", sort: 1000 },
  { code: "ln", name: "Lingala", sort: 1000 },
  { code: "lt", name: "Lithuanian", sort: 1000 },
  { code: "lu", name: "Luba-Katanga", sort: 1000 },
  { code: "mk", name: "Macedonian", sort: 1000 },
  { code: "mg", name: "Malagasy", sort: 1000 },
  { code: "ms", name: "Malay", sort: 1000 },
  { code: "ml", name: "Malayalam", sort: 1000 },
  { code: "mt", name: "Maltese", sort: 1000 },
  { code: "gv", name: "Manx", sort: 1000 },
  { code: "mi", name: "Maori", sort: 1000 },
  { code: "mr", name: "Marathi", sort: 1000 },
  { code: "mh", name: "Marshallese", sort: 1000 },
  { code: "mn", name: "Mongolian", sort: 1000 },
  { code: "na", name: "Nauru", sort: 1000 },
  { code: "nv", name: "Navajo, Navaho", sort: 1000 },
  { code: "nd", name: "Northern Ndebele", sort: 1000 },
  { code: "ng", name: "Ndonga", sort: 1000 },
  { code: "ne", name: "Nepali", sort: 1000 },
  { code: "se", name: "Northern Sami", sort: 1000 },
  { code: "no", name: "Norwegian", sort: 1000 },
  { code: "nb", name: "Norwegian Bokmål", sort: 1000 },
  { code: "nn", name: "Norwegian Nynorsk", sort: 1000 },
  { code: "ii", name: "Nuosu, Sichuan Yi", sort: 1000 },
  { code: "oj", name: "Ojibwa", sort: 1000 },
  { code: "or", name: "Oriya", sort: 1000 },
  { code: "om", name: "Oromo", sort: 1000 },
  { code: "os", name: "Ossetian, Ossetic", sort: 1000 },
  { code: "pi", name: "Pali", sort: 1000 },
  { code: "pa", name: "Panjabi, Punjabi", sort: 1000 },
  { code: "ps", name: "Pashto, Pushto", sort: 1000 },
  { code: "fa", name: "Persian", sort: 1000 },
  { code: "pl", name: "Polish", sort: 100 },
  { code: "pt", name: "Portuguese", sort: 20 },
  { code: "qu", name: "Quechua", sort: 1000 },
  { code: "ro", name: "Romanian", sort: 1000 },
  { code: "rm", name: "Romansh", sort: 1000 },
  { code: "rn", name: "Rundi", sort: 1000 },
  { code: "ru", name: "Russian", sort: 50 },
  { code: "sm", name: "Samoan", sort: 1000 },
  { code: "sg", name: "Sango", sort: 1000 },
  { code: "sa", name: "Sanskrit", sort: 1000 },
  { code: "sc", name: "Sardinian", sort: 1000 },
  { code: "sr", name: "Serbian", sort: 1000 },
  { code: "sn", name: "Shona", sort: 1000 },
  { code: "sd", name: "Sindhi", sort: 1000 },
  { code: "si", name: "Sinhala, Sinhalese", sort: 1000 },
  { code: "sk", name: "Slovak", sort: 1000 },
  { code: "sl", name: "Slovenian", sort: 1000 },
  { code: "so", name: "Somali", sort: 1000 },
  { code: "st", name: "Sotho, Southern", sort: 1000 },
  { code: "nr", name: "South Ndebele", sort: 1000 },
  { code: "es", name: "Spanish", sort: 10 },
  { code: "su", name: "Sundanese", sort: 1000 },
  { code: "sw", name: "Swahili", sort: 1000 },
  { code: "ss", name: "Swati", sort: 1000 },
  { code: "sv", name: "Swedish", sort: 1000 },
  { code: "tl", name: "Tagalog", sort: 1000 },
  { code: "ty", name: "Tahitian", sort: 1000 },
  { code: "tg", name: "Tajik", sort: 1000 },
  { code: "ta", name: "Tamil", sort: 1000 },
  { code: "tt", name: "Tatar", sort: 1000 },
  { code: "te", name: "Telugu", sort: 1000 },
  { code: "th", name: "Thai", sort: 1000 },
  { code: "bo", name: "Tibetan", sort: 1000 },
  { code: "ti", name: "Tigrinya", sort: 1000 },
  { code: "to", name: "Tonga (Tonga Islands)", sort: 1000 },
  { code: "ts", name: "Tsonga", sort: 1000 },
  { code: "tn", name: "Tswana", sort: 1000 },
  { code: "tr", name: "Turkish", sort: 1000 },
  { code: "tk", name: "Turkmen", sort: 1000 },
  { code: "tw", name: "Twi", sort: 1000 },
  { code: "ug", name: "Uighur, Uyghur", sort: 1000 },
  { code: "uk", name: "Ukrainian", sort: 1000 },
  { code: "ur", name: "Urdu", sort: 1000 },
  { code: "uz", name: "Uzbek", sort: 1000 },
  { code: "ve", name: "Venda", sort: 1000 },
  { code: "vi", name: "Vietnamese", sort: 1000 },
  { code: "vo", name: "Volap_k", sort: 1000 },
  { code: "wa", name: "Walloon", sort: 1000 },
  { code: "cy", name: "Welsh", sort: 1000 },
  { code: "fy", name: "Western Frisian", sort: 1000 },
  { code: "wo", name: "Wolof", sort: 1000 },
  { code: "xh", name: "Xhosa", sort: 1000 },
  { code: "yi", name: "Yiddish", sort: 1000 },
  { code: "yo", name: "Yoruba", sort: 1000 },
  { code: "za", name: "Zhuang, Chuang", sort: 1000 },
  { code: "zu", name: "Zulu" }
];

export default languages;
