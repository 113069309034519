import React, { Fragment, useState, useEffect } from "react";
import clsx from "clsx";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  Icon,
  CircularProgress,
  Dialog,
  useMediaQuery,
  useTheme,
  Button,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  TextField,
  InputAdornment,
  Input,
  IconButton,
  InputLabel,
  OutlinedInput,
  Select,
  FormControl,
  MenuItem,
  ListItemText,
  Checkbox
} from "@material-ui/core";

import {
  deleteTutor,
  getTutors,
  clearErrorTutors,
  setCurrentTutor,
  addTutor,
  updateTutor,
  clearCurrentTutor,
  setOpenTutor
} from "../actions/tutors";

import interestsList from "../lib/interestsList";
import timezones from "../lib/timezones";
import languages from "../lib/languages";
import "./TutorForm.css";

import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData
} from "react-country-region-selector";
import typesOfCourse from "../lib/typesOfCourse";

const TutorForm = ({
  current,
  open,
  clearCurrentTutor,
  updateTutor,
  setOpenTutor,
  addTutor,
  loading
}) => {
  const classes = useStyles();
  let emptyTutor = {};
  const [tutor, setTutor] = useState(emptyTutor);

  const { name, email, password, roles, showPassword } = tutor;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  const InputProps = {
    classes: {
      root: classes.outlinedRoot
    }
  };

  useEffect(() => {
    emptyTutor = {
      isDraft: true,
      user: { firstname: "", lastname: "", email: "" },
      gender: "Female",
      age: "28-35",
      interests: [...interestsList].sort(() => 0.5 - Math.random()).slice(0, 5),
      country: "United States",
      region: "New York",

      tagline: "",
      timezone: "(UTC-05:00) Eastern Time (US & Canada)",
      years_teaching_languages:
        Math.floor(Math.random() * (Math.floor(10) - Math.ceil(5))) +
        Math.ceil(5),
      languages: [
        {
          name: "English",
          price:
            Math.floor(Math.random() * (Math.floor(30) - Math.ceil(10))) +
            Math.ceil(10),
          price_trial:
            Math.floor(Math.random() * (Math.floor(5) - Math.ceil(0))) +
            Math.ceil(0),
          max_students:
            Math.floor(Math.random() * (Math.floor(7) - Math.ceil(1))) +
            Math.ceil(1),
          price_extra_student:
            Math.floor(Math.random() * (Math.floor(10) - Math.ceil(4))) +
            Math.ceil(4),
          different_price_types: false,
          types_of_course: [{ name: "General" }, { name: "Adult" }]
        }
      ],
      speak_languages: [{ name: "English" }, { name: "Spanish" }],
      minimum_hours: [1, 2, 5, 10][
        Math.floor(Math.random() * (Math.floor(4) - Math.ceil(0))) +
          Math.ceil(0)
      ],
      work_experience: "",
      qualifications: [],
      description: ""
    };
    if (current !== null) {
      
      setTutor({
        ...current,
        ...current.profile,
        interests: current.profile.interests.map((int) => int.name),
        user: current,
        _id: current._id,
        profile_id: current.profile._id,
        profile: current.profile
      });
    } else {
      if (!open)
        emptyTutor.interests = [...interestsList]
          .sort(() => 0.5 - Math.random())
          .slice(0, 5);
      setTutor(emptyTutor);
    }
  }, [current, open]);

  function handleClose() {
    setOpenTutor(false);
    clearCurrentTutor();
  }
  function onSubmit(e) {
    e.preventDefault();

    try {
      if (!current) {
        addTutor(tutor);
      } else {
        updateTutor(tutor);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const clearAll = () => {
    setTutor(emptyTutor);
    clearCurrentTutor();
  };
  const onChange = (e, val = null, index = null) => {
    if (e === "region" || e === "country") {
      setTutor({ ...tutor, [e]: val });
    } else if (e === "types_of_course") {
      let tmpArr = [];
      val.forEach((targ) => {
        if (targ.name) tmpArr.push(targ);
        else tmpArr.push({ name: targ });
      });

      setTutor({
        ...tutor,
        languages: [
          ...tutor.languages.slice(0, index),
          { ...tutor.languages[index], types_of_course: tmpArr },
          ...tutor.languages.slice(index + 1)
        ]
      });
    } else if (
      e === "price" ||
      e === "price_trial" ||
      e === "max_students" ||
      e === "price_extra_student"
    ) {
      setTutor({
        ...tutor,
        languages: [
          ...tutor.languages.slice(0, index),
          { ...tutor.languages[index], [e]: val },
          ...tutor.languages.slice(index + 1)
        ]
      });
    } else if (e === "qualifications") {
      setTutor({
        ...tutor,
        qualifications: [
          ...tutor.qualifications.slice(0, index),
          { ...tutor.qualifications[index], description: val },
          ...tutor.qualifications.slice(index + 1)
        ]
      });
    } else if (e.target.name === "languages") {
      let tmpArr = [];
      e.target.value.forEach((targ) => {
        if (targ.name) tmpArr.push(targ);
        else
          tmpArr.push({
            name: targ,

            price: tutor.languages[0].price,
            price_trial: tutor.languages[0].price_trial,
            max_students: tutor.languages[0].max_students,
            price_extra_student: tutor.languages[0].price_extra_student,
            different_price_types: false,
            types_of_course: tutor.languages[0].types_of_course
          });
      });

      if (
        !tutor.speak_languages.find(
          (sl) => sl.name === tmpArr[tmpArr.length - 1].name
        )
      ) {
        setTutor({
          ...tutor,
          languages: tmpArr,
          speak_languages: [...tutor.speak_languages, tmpArr[tmpArr.length - 1]]
        });
      } else {
        setTutor({
          ...tutor,
          languages: tmpArr
        });
      }
    } else if (e.target.name === "speak_languages") {
      let tmpArr = [];
      e.target.value.forEach((targ) => {
        if (targ.name) tmpArr.push(targ);
        else tmpArr.push({ name: targ });
      });
      setTutor({
        ...tutor,
        speak_languages: tmpArr
      });
    } else if (e.target.name === "firstname") {
      setTutor({
        ...tutor,
        user: {
          ...tutor.user,
          [e.target.name]: e.target.value
        }
      });
    } else if (e.target.name === "lastname") {
      setTutor({
        ...tutor,
        user: {
          ...tutor.user,
          [e.target.name]: e.target.value
        }
      });
    } else if (e.target.name === "email") {
      setTutor({
        ...tutor,
        user: {
          ...tutor.user,

          email: e.target.value
        }
      });
    } else setTutor({ ...tutor, [e.target.name]: e.target.value });
  };

  return (
    <React.Fragment>
      <CssBaseline />

      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        fullScreen={true}
        style={
          !useMediaQuery(useTheme().breakpoints.down("sm"))
            ? { width: "90%", height: "90%", margin: "auto" }
            : {}
        }
        open={current || open ? true : false}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        scroll="paper"
      >
        <DialogTitle
          id="max-width-dialog-title"
          color="primary"
          className={classes.formHeader}
        >
          {!current && open
            ? "New Tutor"
            : current && !open
            ? "Edit Tutor"
            : ""}
        </DialogTitle>

        <DialogContent dividers={true}>
          <form onSubmit={onSubmit}>
            <input type="submit" style={{ display: "none" }} />
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12} md={3}>
                <TextField
                  autoFocus
                  margin="dense"
                  name="firstname"
                  label="First Name*"
                  value={tutor.user && tutor.user.firstname}
                  onChange={onChange}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>{" "}
              <Grid item xs={12} md={3}>
                <TextField
                  margin="dense"
                  name="lastname"
                  label="Last Name*"
                  value={tutor.user && tutor.user.lastname}
                  onChange={onChange}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>{" "}
              <Grid item xs={12} md={3}>
                <TextField
                  name="email"
                  label="E-mail*"
                  type="email"
                  value={tutor.user && tutor.user.email}
                  onChange={onChange}
                  className={classes.textField}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>{" "}
              <Grid item xs={12} md={3}>
                <FormControl
                  className={classes.formControl}
                  variant="outlined"
                  fullWidth
                  margin="dense"
                >
                  <InputLabel
                    style={{ width: "70px" }}
                    id="demo-mutiple-checkbox-label"
                  >
                    Gender*
                  </InputLabel>
                  <Select
                    variant="outlined"
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    name="gender"
                    value={tutor.gender}
                    onChange={onChange}
                    input={
                      <OutlinedInput
                        labelWidth={50}
                        classes={{ input: classes.formControl }}
                        margin="dense"
                      />
                    }
                  >
                    {["Male", "Female"].map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>{" "}
              <Grid item xs={12} md={2}>
                <FormControl
                  className={classes.formControl}
                  variant="outlined"
                  fullWidth
                  margin="dense"
                >
                  <InputLabel
                    style={{ width: "70px" }}
                    id="demo-mutiple-checkbox-label"
                  >
                    Age*
                  </InputLabel>
                  <Select
                    variant="outlined"
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    name="age"
                    value={tutor.age}
                    onChange={onChange}
                    input={
                      <OutlinedInput
                        labelWidth={30}
                        classes={{ input: classes.formControl }}
                        margin="dense"
                      />
                    }
                  >
                    {[
                      "Prefer not to say",
                      "18-22",
                      "23-27",
                      "28-35",
                      "36-45",
                      "46-55",
                      "56-65",
                      "66-75",
                      "76 or more"
                    ].map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl
                  className={classes.formControl}
                  fullWidth
                  variant="outlined"
                  margin="dense"
                >
                  <InputLabel id="demo-mutiple-checkbox-label">
                    Interests*
                  </InputLabel>
                  <Select
                    variant="outlined"
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    name="interests"
                    multiple
                    value={tutor.interests || []}
                    onChange={onChange}
                    input={
                      <OutlinedInput
                        labelWidth={70}
                        classes={{ input: classes.formControl }}
                        margin="dense"
                      />
                    }
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {interestsList.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox
                          checked={
                            tutor.interests &&
                            tutor.interests.indexOf(name) > -1
                          }
                        />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <CountryDropdown
                  value={tutor.country}
                  classes={"form-input select-country"}
                  priorityOptions={[
                    "US",
                    "CA",
                    "GB",
                    "IE",
                    "AU",
                    "NZ",
                    "DE",
                    "PT",
                    "ES",
                    "BR"
                  ]}
                  input={<TextField />}
                  onChange={(val) => onChange("country", val)}
                  priorityRepeat={true}
                />
                <br />
                <RegionDropdown
                  country={tutor.country}
                  classes={"form-input select-region"}
                  value={tutor.region}
                  onChange={(val) => onChange("region", val)}
                />
              </Grid>{" "}
              <Grid item xs={12} md={3}>
                <FormControl
                  className={classes.formControl}
                  variant="outlined"
                  fullWidth
                  margin="dense"
                >
                  <InputLabel
                    style={{ width: "70px" }}
                    id="demo-mutiple-checkbox-label"
                  >
                    Timezone*
                  </InputLabel>
                  <Select
                    variant="outlined"
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    name="timezone"
                    value={tutor.timezone}
                    onChange={onChange}
                    input={
                      <OutlinedInput
                        labelWidth={70}
                        classes={{ input: classes.formControl }}
                        margin="dense"
                      />
                    }
                  >
                    {timezones.map((tz) => (
                      <MenuItem key={tz.text} value={tz.text}>
                        {tz.text}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>{" "}
              <Grid item xs={12} md={12}>
                <TextField
                  margin="dense"
                  name="tagline"
                  label={
                    "Tagline (" +
                    (tutor.tagline ? tutor.tagline.length : "0") +
                    "/200 characters)*"
                  }
                  value={tutor.tagline}
                  onChange={onChange}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>{" "}
              <Grid item xs={12} md={12}>
                <TextField
                  margin="dense"
                  name="description"
                  label={
                    "Profile (" +
                    (tutor.description ? tutor.description.length : "0") +
                    "/800 characters)*"
                  }
                  value={tutor.description}
                  multiline
                  rows={3}
                  onChange={onChange}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>{" "}
              <Grid item xs={12} md={12}>
                <TextField
                  margin="dense"
                  name="work_experience"
                  label={
                    "Work Experience (" +
                    (tutor.work_experience
                      ? tutor.work_experience.length
                      : "0") +
                    "/500 characters)* "
                  }
                  value={tutor.work_experience}
                  multiline
                  rows={2}
                  onChange={onChange}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>{" "}
              <Grid item xs={12} md={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={(e) =>
                    setTutor({
                      ...tutor,
                      qualifications: [
                        ...tutor.qualifications,
                        { description: "" }
                      ]
                    })
                  }
                >
                  Add Qualification
                </Button>
              </Grid>{" "}
              {tutor.qualifications &&
                tutor.qualifications.map((q, index) => (
                  <Grid item xs={12} md={2}>
                    <TextField
                      margin="dense"
                      name="qualifications"
                      label="Qualification"
                      value={q.description}
                      onChange={(e) =>
                        onChange("qualifications", e.target.value, index)
                      }
                      className={classes.textField}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                ))}
            </Grid>
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12} md={2}>
                <TextField
                  margin="dense"
                  type="number"
                  name="years_teaching_languages"
                  label="Years Teaching (0-80)*"
                  value={tutor.years_teaching_languages}
                  onChange={onChange}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>{" "}
              <Grid item xs={12} md={4}>
                <FormControl
                  className={classes.formControl}
                  fullWidth
                  variant="outlined"
                  margin="dense"
                >
                  <InputLabel id="demo-mutiple-checkbox-label">
                    Languages Taught*
                  </InputLabel>
                  <Select
                    variant="outlined"
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    name="languages"
                    multiple
                    value={tutor.languages || []}
                    onChange={onChange}
                    input={
                      <OutlinedInput
                        labelWidth={140}
                        classes={{ input: classes.formControl }}
                        margin="dense"
                      />
                    }
                    renderValue={(selected) =>
                      selected.map((sel) => sel.name).join(", ")
                    }
                  >
                    {languages.map((lang) => (
                      <MenuItem key={lang.name} value={lang.name}>
                        <Checkbox
                          checked={
                            tutor.languages &&
                            tutor.languages.find((l) => l.name === lang.name)
                          }
                        />
                        <ListItemText primary={lang.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl
                  className={classes.formControl}
                  fullWidth
                  variant="outlined"
                  margin="dense"
                >
                  <InputLabel id="demo-mutiple-checkbox-label">
                    Languages Spoken*
                  </InputLabel>
                  <Select
                    variant="outlined"
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    name="speak_languages"
                    multiple
                    value={tutor.speak_languages || []}
                    onChange={onChange}
                    input={
                      <OutlinedInput
                        labelWidth={140}
                        classes={{ input: classes.formControl }}
                        margin="dense"
                      />
                    }
                    renderValue={(selected) =>
                      selected.map((sel) => sel.name).join(", ")
                    }
                  >
                    {languages.map((lang) => (
                      <MenuItem key={lang.name} value={lang.name}>
                        <Checkbox
                          checked={
                            tutor.speak_languages &&
                            tutor.speak_languages.find(
                              (l) => l.name === lang.name
                            )
                          }
                        />
                        <ListItemText primary={lang.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  margin="dense"
                  type="number"
                  name="minimum_hours"
                  label="Minimum Hours*"
                  value={tutor.minimum_hours}
                  onChange={onChange}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              {tutor.languages &&
                tutor.languages.map((lang, index) => (
                  <Fragment>
                    <Grid item xs={12} md={12}>
                      <span>{lang.name} class details</span>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl
                        className={classes.formControl}
                        fullWidth
                        variant="outlined"
                        margin="dense"
                      >
                        <InputLabel id="demo-mutiple-checkbox-label">
                          Course Types*
                        </InputLabel>
                        <Select
                          variant="outlined"
                          labelId="demo-mutiple-checkbox-label"
                          id="demo-mutiple-checkbox"
                          name="types_of_course"
                          multiple
                          value={lang.types_of_course || []}
                          onChange={(e) =>
                            onChange("types_of_course", e.target.value, index)
                          }
                          input={
                            <OutlinedInput
                              labelWidth={140}
                              classes={{ input: classes.formControl }}
                              margin="dense"
                            />
                          }
                          renderValue={(selected) =>
                            selected.map((sel) => sel.name).join(", ")
                          }
                        >
                          {typesOfCourse.map((type) => (
                            <MenuItem key={type.name} value={type.name}>
                              <Checkbox
                                checked={
                                  lang.types_of_course &&
                                  lang.types_of_course.find(
                                    (t) => t.name === type.name
                                  )
                                }
                              />
                              <ListItemText primary={type.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <TextField
                        margin="dense"
                        type="number"
                        name="price"
                        label="Price $*"
                        value={lang.price}
                        onChange={(e) =>
                          onChange("price", e.target.value, index)
                        }
                        className={classes.textField}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <TextField
                        margin="dense"
                        type="number"
                        name="price_trial"
                        label="Price Trial $*"
                        value={lang.price_trial}
                        onChange={(e) =>
                          onChange("price_trial", e.target.value, index)
                        }
                        className={classes.textField}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <TextField
                        margin="dense"
                        type="number"
                        name="max_students"
                        label="Max Students (1-8)*"
                        value={lang.max_students}
                        onChange={(e) =>
                          onChange("max_students", e.target.value, index)
                        }
                        className={classes.textField}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <TextField
                        margin="dense"
                        type="number"
                        name="price_extra_student"
                        label="Price Extra Student $*"
                        value={lang.price_extra_student}
                        onChange={(e) =>
                          onChange("price_extra_student", e.target.value, index)
                        }
                        className={classes.textField}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                  </Fragment>
                ))}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions className={classes.formFooter}>
          <Button
            onClick={onSubmit}
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            Save
          </Button>
          <Button
            onClick={handleClose}
            variant="contained"
            color="secondary"
            disabled={loading}
          >
            Cancel
          </Button>
        </DialogActions>
        <Fragment>
          <CssBaseline />
        </Fragment>
      </Dialog>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650
  },
  progress: {
    position: "absolute",
    top: "60px",
    right: "20px"
  },
  modalPaper: {
    width: "10%"
  },

  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  formHeader: {
    backgroundColor: "#1976d2",
    color: "white"
  },
  formFooter: {
    backgroundColor: "gainsboro",
    padding: "10px"
  },
  formControl: {
    height: "18px"
  },
  outlinedRoot: {
    "&:hover": {
      border: "1px solid red"
    }
  }
}));

const mapStateToProps = (state) => ({
  tutors: state.tutors.all,
  loading: state.tutors.loading,
  error: state.tutors.error,
  current: state.tutors.current,
  open: state.tutors.open
});

const mapDispatchToProps = (dispatch) => ({
  deleteTutor: (id) => dispatch(deleteTutor(id)),
  getTutors: () => dispatch(getTutors()),
  clearErrorTutors: () => dispatch(clearErrorTutors()),
  setCurrentTutor: (tutor) => dispatch(setCurrentTutor(tutor)),
  setOpenTutor: (should) => dispatch(setOpenTutor(should)),
  clearCurrentTutor: () => dispatch(clearCurrentTutor()),
  addTutor: (tutor) => dispatch(addTutor(tutor)),
  updateTutor: (tutor) => dispatch(updateTutor(tutor))
});
export default connect(mapStateToProps, mapDispatchToProps)(TutorForm);
