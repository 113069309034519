const initialState = {
  all: [],
  current: null,
  filtered: null,
  filter: null,
  error: null,
  loading: true,
  bypassIo: false,
  open: false,
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_USER":
      return { ...state, all: [...state.all, action.payload] };
    case "DELETE_USER":
      return {
        ...state,
        all: state.all.filter((user) => user._id !== action.payload),
      };
    case "UPDATE_USER":
      return {
        ...state,
        all: state.all.map((user) => (user._id !== action.payload._id ? user : action.payload)),
      };
    case "GET_USERS":
      return { ...state, all: action.payload };
    case "CURRENT_USER":
      return { ...state, current: action.payload };
    case "CLEAR_CURRENT_USER":
      return { ...state, current: null };
    case "SET_BYPASS_IO_USERS":
      return { ...state, bypassIo: action.payload };
    case "SET_OPEN_USER":
      return { ...state, open: action.payload };
    case "ERROR_USERS":
      let tmpError = action.payload;
      try {
        tmpError = JSON.parse(tmpError.response.data.message);
        tmpError.list = tmpError.list
          ? "<ul>" + tmpError.list.map((error) => "<li>" + error + "</li>").join("") + "</ul>"
          : "";
      } catch (err) {
        tmpError = { msg: tmpError.response.data.message, list: "" };
      }

      return { ...state, error: tmpError };

    case "CLEAR_ERROR_USERS":
      return { ...state, error: null };
    case "LOADING_USERS":
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

export default users;
