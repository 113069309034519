import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Redirect, Switch, useLocation } from "react-router-dom";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";

import setAuthToken from "./utils/setAuthToken";
import Home from "./user/Home";
import Users from "./user/Users";
import UserRoles from "./user/UserRoles";
import Tutors from "./ltd/Tutors";
import Auth from "./user/Auth";
import MainNavigation from "./shared/components/Navigation/MainNavigation";
import { AuthContext } from "./shared/context/auth-context";
import { useAuth } from "./shared/hooks/auth-hook";
import { ConfirmProvider } from "material-ui-confirm";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#B31728",
    },
    green: {
      main: green[500],
      contrastText: "#fff",
    },
  },
});

const successThemePalette = { primary: theme.palette.success };
const successTheme = createMuiTheme({ ...theme, palette: successThemePalette });
const SuccessThemeProvider = (props) => {
  return <MuiThemeProvider theme={successTheme}>{props.children}</MuiThemeProvider>;
};

const App = (props) => {
  const { token, login, logout, userId, roles } = useAuth();

  //

  //useEffect(() => {console.log(location.pathname);}, [token]);

  let routes;

  if (token) {
    setAuthToken(token, logout);
  }

  if (token) {
    routes = (
      <Switch>
        <Route path="/user-roles" exact component={UserRoles} />
        <Route path="/users" exact component={Users} />
        <Route
          path="/ltd/tutors/pending"
          exact
          render={(props) => <Tutors {...props} status={"Pending"} />}
        />
        <Route
          path="/ltd/tutors/approved"
          exact
          render={(props) => <Tutors {...props} status={"Approved"} />}
        />
        <Route
          path="/ltd/tutors/draft"
          exact
          render={(props) => <Tutors {...props} status={"Draft"} />}
        />
        <Route path="/ltd/tutors" exact render={(props) => <Tutors {...props} status={"All"} />} />
        <Route
          path="/ltd/tutors/rejected"
          exact
          render={(props) => <Tutors {...props} status={"Rejected"} />}
        />
        <Route
          path="/ltd/tutors/blocked"
          exact
          render={(props) => <Tutors {...props} status={"Blocked"} />}
        />

        <Route path="/" exact render={(props) => <Home {...props} isLoggedIn={token} />} />

        <Redirect to="/" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route path="/" render={(props) => <Home {...props} isLoggedIn={token} />} />
      </Switch>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        roles: roles,
        login: login,
        logout: logout,
      }}
    >
      <ConfirmProvider>
        <MuiThemeProvider theme={theme}>
          <Router>
            {token && <MainNavigation />}
            <main>{routes}</main>
          </Router>
        </MuiThemeProvider>
      </ConfirmProvider>
    </AuthContext.Provider>
  );
};

export default App;
