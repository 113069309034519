import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import Icon from "@material-ui/core/Icon";
import { setOpenTutor, setCurrentTutor } from "../actions/tutors";
import { Grid } from "@material-ui/core";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./ApproveButtons.css";

const useStyles = makeStyles((theme) => ({
  approve: {
    marginRight: "10px",
    backgroundColor: "green",
  },
  disapprove: {
    marginRight: "10px",
    backgroundColor: "red",
  },
  block: {
    marginRight: "10px",
    backgroundColor: "black",
  },
  fixedButtons: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  rejectLetter: {
    position: "fixed",
    padding: "10px",
    backgroundColor: "#eee",
    bottom: theme.spacing(10),
    right: theme.spacing(2),
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.26)",
    width: "400px",
    height: "550px",
  },
}));
const ApproveButtons = ({ tutor, approve, disapprove, block, confirm, setCurrentTutor }) => {
  const classes = useStyles();
  const defaultLetter =
    "Dear " +
    tutor.firstname +
    `,<br/><br/>Unfortunately your Tutor Profile doesn't meet our profile standards, because of:
    <br/><ul><li>ENTER THE PROFILE PROBLEMS HERE</li><li>bla</li><li>ble</li><li>bli</li></ul><br/>
    <a href="https://www.lovlan.com/user/login">Click here to login to your account</a>, and adjust your profile. As soon as you have made the changes, we will review your profile again. As soon as we can approve your profile, you can start receiving students.
    <br/><br/>Kind Regards,<br/>The Lovlan Team<br/>www.lovlan.com`;
  const [rejectLetter, setRejectLetter] = useState(null);

  return (
    <Fragment>
      <div
        style={{ ...(rejectLetter ? { display: "block" } : { display: "none" }) }}
        className={classes.rejectLetter}
      >
        <div>
          {rejectLetter && (
            <CKEditor
              editor={ClassicEditor}
              data={rejectLetter}
              onInit={(editor) => {
                // You can store the "editor" and use when it is needed.
                console.log("Editor is ready to use!", editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setRejectLetter(data);
              }}
              onBlur={(event, editor) => {
                console.log("Blur.", editor);
              }}
              onFocus={(event, editor) => {
                console.log("Focus.", editor);
              }}
            />
          )}
        </div>
        <br />
        <Button
          style={{ marginBottom: "10px", float: "left" }}
          color="secondary"
          variant="outlined"
          type="button"
          onClick={() => setRejectLetter(null)}
        >
          Cancel
        </Button>
        <Button
          style={{ marginBottom: "10px", float: "right" }}
          color="primary"
          variant="contained"
          type="button"
          onClick={() => disapprove(tutor, rejectLetter)}
        >
          Reject & Send
        </Button>
      </div>
      {tutor && (
        <div className={classes.fixedButtons}>
          {!tutor.profile.isDraft && tutor.profile.approved !== 1 && (
            <Fab
              onClick={() => approve(confirm, tutor)}
              color="primary"
              aria-label="Approve profile"
              title="Approve profile"
              className={classes.approve}
            >
              <Icon>check</Icon>
            </Fab>
          )}
          {!tutor.profile.isDraft && tutor.profile.approved !== -1 && (
            <Fab
              onClick={() => setRejectLetter(defaultLetter)}
              color="secondary"
              aria-label="Reject"
              title="Reject profile"
              className={classes.disapprove}
            >
              <Icon>cancel</Icon>
            </Fab>
          )}

          <Fab
            onClick={() => block(confirm, tutor)}
            color="primary"
            aria-label="Add"
            title="Delete user"
            className={classes.block}
          >
            <Icon>delete</Icon>
          </Fab>

          <Fab
            onClick={() => setCurrentTutor(tutor)}
            color="primary"
            aria-label="Edit"
            title="Edit Profile"
          >
            <Icon>edit</Icon>
          </Fab>
        </div>
      )}
    </Fragment>
  );
};

export default ApproveButtons;
