import React from 'react';
import {

  Button,
  Snackbar,

} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

const ErrorSnack = ({error, clearError}) => {
  
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={error}
      autoHideDuration={6000}
      onClose={() => clearError()}
    >
      <Alert
        onClose={() => clearError()}
        severity="error"
        action={
          <Button color="inherit" size="small" onClick={() => clearError()}>
            X
          </Button>
        }
      >
        <AlertTitle>Error</AlertTitle>
        
        {error && error.msg}
        {error && error.list && (
          <div
            dangerouslySetInnerHTML={{
              __html: error.list,
            }}
          />
        )}
      </Alert>
    </Snackbar>
  );
};

export default ErrorSnack;
