export default [
  { name: "General", default: true },
  { name: "Adult", default: true },
  { name: "Business" },
  { name: "Exam preparation" },
  { name: "Special purposes" },
  { name: "Kids" },
  { name: "Pronunciation" },
  { name: "Conversation" }
];
