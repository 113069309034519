import React, { Fragment, useState, useEffect } from "react";
import clsx from "clsx";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  Icon,
  CircularProgress,
  Dialog,
  useMediaQuery,
  useTheme,
  Button,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  TextField,
  InputAdornment,
  Input,
  IconButton,
  InputLabel,
  OutlinedInput,
  Select,
  FormControl,
  MenuItem,
  ListItemText,
  Checkbox,
} from "@material-ui/core";

import {
  deleteUser,
  getUsers,
  clearErrorUsers,
  setCurrentUser,
  addUser,
  updateUser,
  clearCurrentUser,
  setOpenUser,
} from "../actions/users";

const emptyUser = {
  name: "",
  email: "",
  password: "",
  roles: [],
  showPassword: false,
};

const UserForm = ({
  current,
  open,
  clearCurrentUser,
  updateUser,
  setOpenUser,
  addUser,
  loading,
}) => {
  const classes = useStyles();
  const [user, setUser] = useState(emptyUser);

  const { name, email, password, roles, showPassword } = user;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    if (current !== null) {
      setUser(current);
    } else {
      setUser(emptyUser);
    }
  }, [current, open]);

  function handleClose() {
    setOpenUser(false);
    clearCurrentUser();
  }
  function onSubmit(e) {
    e.preventDefault();

    try {
      if (!current) {
        addUser(user);
      } else {
        updateUser(user);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const clearAll = () => {
    setUser(emptyUser);
    clearCurrentUser();
  };

  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  return (
    <React.Fragment>
      <CssBaseline />

      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        fullScreen={true}
        style={
          !useMediaQuery(useTheme().breakpoints.down("sm"))
            ? { width: "40%", height: "35%", margin: "auto" }
            : {}
        }
        open={current || open ? true : false}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        scroll="paper"
      >
        <DialogTitle id="max-width-dialog-title" color="primary" className={classes.formHeader}>
          {!current && open ? "New User" : current && !open ? "Edit User" : ""}
        </DialogTitle>

        <DialogContent dividers={true}>
          <form onSubmit={onSubmit}>
          <input type="submit" style={{display:'none'}} />
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  autoFocus
                  margin="dense"
                  name="name"
                  label="Name"
                  value={name}
                  onChange={onChange}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>{" "}
              <Grid item xs={12} md={6}>
                <TextField
                  margin="dense"
                  name="email"
                  label="E-mail"
                  value={email}
                  onChange={onChange}
                  className={classes.textField}
                  variant="outlined"
                  fullWidth
                />
              </Grid>{" "}
              <Grid item xs={12} md={6}>
                <FormControl
                  className={clsx(classes.margin, classes.textField)}
                  variant="outlined"
                  fullWidth
                  margin="dense"
                >
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <OutlinedInput
                    type={showPassword ? "text" : "password"}
                    margin="dense"
                    name="password"
                    id="password"
                    onChange={onChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={(e) =>
                            setUser({
                              ...user,
                              showPassword: !user.showPassword,
                            })
                          }
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          {showPassword ? <Icon>visibility</Icon> : <Icon>visibility_off</Icon>}
                        </IconButton>
                      </InputAdornment>
                    }
                    className={classes.textField}
                    labelWidth={70}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel id="demo-mutiple-checkbox-label">Roles</InputLabel>
                  <Select
                    variant="outlined"
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    name="roles"
                    multiple
                    value={roles}
                    onChange={onChange}
                    input={<Input />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    {["admin", "supervisor", "editor"].map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={roles.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions className={classes.formFooter}>
          <Button
            onClick={onSubmit}
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            Save
          </Button>
          <Button onClick={handleClose} variant="contained" color="secondary" disabled={loading}>
            Cancel
          </Button>
        </DialogActions>
        <Fragment>
          <CssBaseline />
        </Fragment>
      </Dialog>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  progress: {
    position: "absolute",
    top: "60px",
    right: "20px",
  },
  modalPaper: {
    width: "10%",
  },

  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  formHeader: {
    backgroundColor: "#1976d2",
    color: "white",
  },
  formFooter: {
    backgroundColor: "gainsboro",
    padding: "10px",
  },
}));

const mapStateToProps = (state) => ({
  users: state.users.all,
  loading: state.users.loading,
  error: state.users.error,
  current: state.users.current,
  open: state.users.open,
});

const mapDispatchToProps = (dispatch) => ({
  deleteUser: (id) => dispatch(deleteUser(id)),
  getUsers: () => dispatch(getUsers()),
  clearErrorUsers: () => dispatch(clearErrorUsers()),
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  setOpenUser: (should) => dispatch(setOpenUser(should)),
  clearCurrentUser: () => dispatch(clearCurrentUser()),
  addUser: (user) => dispatch(addUser(user)),
  updateUser: (user) => dispatch(updateUser(user)),
});
export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
