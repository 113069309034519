import React, { useEffect, useState, useContext, Fragment } from "react";
import { connect } from "react-redux";

import {
  deleteTutor,
  getTutors,
  clearErrorTutors,
  setCurrentTutor,
  clearCurrentTutor,
  setBypassIoTutors,
  updateTutor,
} from "../actions/tutors";

import subscribeTo from "../utils/subscribeTo";
import { AuthContext } from "../shared/context/auth-context";
import TutorForm from "./TutorForm";
import AddTutorBtn from "./AddTutorBtn";
import TutorView from "./TutorView";
import ApproveButtons from "./ApproveButtons";
import ErrorSnack from "../shared/components/UIElements/ErrorSnack";
import "./Tutors.css";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Icon, Breadcrumbs, Link, useTheme, CircularProgress, Grid } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useConfirm } from "material-ui-confirm";

const Tutors = ({
  status,
  tutors,
  loading,
  filter,
  filtered,
  error,
  current,
  bypassIo,
  clearErrorTutors,
  updateTutor,
  setCurrentTutor,
  clearCurrentTutor,
  setBypassIoTutors,
  deleteTutor,
  getTutors,
  dispatch,
}) => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const confirm = useConfirm();
  const theme = useTheme();
  const [viewProfile, setViewProfile] = useState(null);

  useEffect(() => {
    if ((!tutors) && !error) getTutors({ status });
    if (!loading) {
      subscribeTo("tutorsChanged", (err, data) => {
        console.log("tutors changeeeeeeeeeeed", bypassIo);
        if (!bypassIo) getTutors({ status });
        else setBypassIoTutors(false);
      });
      return () => {
        subscribeTo("tutorsChanged", null);
      };
    }
  }, [loading, bypassIo, status]);

  const onClickDelete = (confirm, tutor) => {
    confirm({
      description: `Are you sure you want to DELETE the tutor "${tutor.firstname} ${tutor.lastname}"?`,
      title: "WARNING",
      cancellationText: "Cancel",
      confirmationText: "Confirm",
    }).then(() => {
      setViewProfile(null);
      deleteTutor(tutor._id);
    });
  };

  const onClickApprove = (confirm, tutor) => {
    confirm({
      description: `Are you sure you want to approve the profile for "${tutor.firstname} ${tutor.lastname}". This will send an e-mail to ${tutor.email}`,
      title: "WARNING",
      cancellationText: "Cancel",
      confirmationText: "Confirm",
    }).then(() => {
      setViewProfile(null);
      updateTutor({
        ...tutor,
        profile: { ...tutor.profile, approved: 1, formChanged:[] }
      });
    });
  };

  const onClickDisapprove = (tutor, rejectMessage) => {
    setViewProfile(null);
    updateTutor({ ...tutor, profile: { ...tutor.profile, approved: -1 }, rejectMessage });
  };

  const displayProfile = (tutor) =>
    !viewProfile || viewProfile._id !== tutor._id ? setViewProfile(tutor) : setViewProfile(null);

  return (
    <React.Fragment>
      <ErrorSnack error={error} clearError={clearErrorTutors} />
      <React.Fragment>
        <CssBaseline />
        <TutorForm current={current} clearCurrentTutor={clearCurrentTutor} />
        <Container maxWidth="xl">
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Link color="inherit" href="/">
              LTD
            </Link>
            <Link color="textPrimary" href="/tutors" aria-current="page">
              {status} Tutor Profiles
            </Link>
          </Breadcrumbs>
          {loading && (
            <CircularProgress
              style={{ position: "absolute", top: "60px", right: "10px" }}
              color="secondary"
            />
          )}
          {filtered && filtered[status] && filtered[status].length === 0 && (
            <h3>No {status} Profiles found</h3>
          )}
          {filtered && filtered[status] && filtered[status].length !== 0 && (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Languages Taught</TableCell>
                    <TableCell>Location</TableCell>

                    <TableCell>Date created</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filtered[status].map((tutor) => (
                    <Fragment key={tutor.profile._id}>
                      <TableRow
                        style={{
                          ...(viewProfile && viewProfile._id === tutor._id
                            ? { backgroundColor: "#eef" }
                            : {}),
                          userSelect: "none",
                          cursor: "pointer"
                        }}
                        className="tutor-row"
                      >
                        <TableCell onClick={(e) => displayProfile(tutor)}>
                          {tutor.blocked
                            ? "Blocked"
                            : tutor.profile.isDraft
                            ? "Draft"
                            : tutor.profile.approved === 1
                            ? "Approved"
                            : tutor.profile.approved === -1
                            ? "Rejected"
                            : "Pending"}
                        </TableCell>
                        <TableCell
                          scope="tutor"
                          onClick={(e) => displayProfile(tutor)}
                        >
                          {tutor.firstname} {tutor.lastname}
                        </TableCell>
                        <TableCell onClick={(e) => displayProfile(tutor)}>
                          {tutor.profile.languages
                            .map((lang) => lang.name)
                            .join(", ")}
                        </TableCell>
                        <TableCell onClick={(e) => displayProfile(tutor)}>
                          {tutor.profile.region}, {tutor.profile.country}
                        </TableCell>
                        <TableCell onClick={(e) => displayProfile(tutor)}>
                          {tutor._id &&
                            new Date(
                              parseInt(tutor._id.substring(0, 8), 16) * 1000
                            )
                              .toISOString()
                              .split("T")[0]}
                        </TableCell>
                      </TableRow>
                      <TutorView
                        show={viewProfile && viewProfile._id === tutor._id}
                        tutor={tutor}
                      />
                    </Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {viewProfile && (
            <ApproveButtons
              tutor={viewProfile}
              approve={onClickApprove}
              disapprove={onClickDisapprove}
              block={onClickDelete}
              block={onClickDelete}
              confirm={confirm}
              setCurrentTutor={setCurrentTutor}
            />
          )}
          {!viewProfile && <AddTutorBtn />}
        </Container>
      </React.Fragment>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableHead: {
    backgroundColor: "#eee",
  },
  progress: {
    position: "absolute",
    top: "60px",
    right: "20px",
  },
  modalPaper: {
    width: "10%",
  },
  approved: {
    color: theme.palette.green.main,
  },
}));

const mapStateToProps = (state) => ({
  tutors: state.tutors.all,
  filter: state.tutors.filter,
  filtered: state.tutors.filtered,
  loading: state.tutors.loading,
  error: state.tutors.error,
  current: state.tutors.current,
  bypassIo: state.tutors.bypassIo,
});

const mapDispatchToProps = (dispatch) => ({
  deleteTutor: (id) => dispatch(deleteTutor(id)),
  getTutors: (status) => dispatch(getTutors(status)),
  clearErrorTutors: () => dispatch(clearErrorTutors()),
  setCurrentTutor: (tutor) => dispatch(setCurrentTutor(tutor)),
  clearCurrentTutor: () => dispatch(clearCurrentTutor()),
  setBypassIoTutors: (bypass) => dispatch(setBypassIoTutors(bypass)),
  updateTutor: (tutor) => dispatch(updateTutor(tutor)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Tutors);
