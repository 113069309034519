import React, { useContext, Fragment } from "react";
import { NavLink } from "react-router-dom";

import { AuthContext } from "../../context/auth-context";
import "./NavLinks.css";
import AuthNavLink from "./AuthNavLink";

import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";

const NavLinks = (props) => {
  const classes = useStyles();
  const auth = useContext(AuthContext);

  return (
    <div className="navbar">
      {auth.isLoggedIn && (
        <Fragment>
          <AuthNavLink to="/ltd/tutors/pending" role="admin">
            New Profiles
          </AuthNavLink>
          <div className="dropdown">
            <button style={{ display: "flex", alignItems: "center" }} className="dropbtn">
              Tutors
              <Icon>expand_more</Icon>
            </button>
            <div className="dropdown-content">
              <AuthNavLink to="/ltd/tutors/approved" role="admin">
                Approved Profiles
              </AuthNavLink>
              <AuthNavLink to="/ltd/tutors/draft" role="admin">
                Draft Profiles
              </AuthNavLink>

              <AuthNavLink to="/ltd/tutors/rejected" role="admin">
                Rejected Profiles
              </AuthNavLink>
              <AuthNavLink to="/ltd/tutors/blocked" role="admin">
                Blocked Accounts
              </AuthNavLink>
            </div>
          </div>
          <div className="dropdown">
            <button style={{ display: "flex", alignItems: "center" }} className="dropbtn">
              LTConnect
              <Icon>expand_more</Icon>
            </button>
            <div className="dropdown-content">
              <AuthNavLink to="/ltc/courses" role="admin">
                Courses
              </AuthNavLink>
              <AuthNavLink to="/ltc/bookings" role="admin">
                Bookings
              </AuthNavLink>
            </div>
          </div>
          <div className="dropdown">
            <button style={{ display: "flex", alignItems: "center" }} className="dropbtn">
              Configuration
              <Icon>expand_more</Icon>
            </button>
            <div className="dropdown-content">
              <AuthNavLink to="/users" role="admin">
                Users
              </AuthNavLink>
            </div>
          </div>
        </Fragment>
      )}
      {auth.isLoggedIn && (
        <a style={{ display: "flex", alignItems: "center" }} onClick={auth.logout} title="Logout">
          <Icon>exit_to_app</Icon>
        </a>
      )}
    </div>
  );
};
const useStyles = makeStyles({
  dropbtn: {

  },
});

export default NavLinks;
