import React, { useEffect, useState, useContext, Fragment } from "react";
import { connect } from "react-redux";

import {
  deleteTutor,
  getTutors,
  clearErrorTutors,
  setCurrentTutor,
  clearCurrentTutor,
  setBypassIoTutors,
  updateTutor
} from "../actions/tutors";

import subscribeTo from "../utils/subscribeTo";
import { AuthContext } from "../shared/context/auth-context";
import TutorForm from "./TutorForm";
import AddTutorBtn from "./AddTutorBtn";
import ErrorSnack from "../shared/components/UIElements/ErrorSnack";
import "./Tutors.css";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  Icon,
  Breadcrumbs,
  Link,
  useTheme,
  CircularProgress,
  Grid
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useConfirm } from "material-ui-confirm";
import AvailabilityTable from "./AvailabilityTable";

const TutorView = ({ tutor, show }) => {
  const classes = useStyles();
  return (
    <TableRow
      style={{
        ...(show ? { display: "table-row" } : { display: "none" })
      }}
      key={"view" + tutor.profile._id}
    >
      <TableCell colSpan={6}>
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12} md={4}>
            <h4>General Details</h4>
            <div>
              <strong>Name:</strong> {tutor.firstname} {tutor.lastname}
            </div>
            <div>
              <strong>Gender:</strong> {tutor.profile.gender}
            </div>
            <div>
              <strong>Age:</strong> {tutor.profile.age}
            </div>
            <div>
              <strong>Interests:</strong>{" "}
              {tutor.profile.interests &&
                tutor.profile.interests
                  .map((interest) => interest.name)
                  .join(", ")}
            </div>
            <div>
              <strong
                style={{
                  ...(tutor.profile.formChanged &&
                  tutor.profile.formChanged.indexOf("tagline") !== -1
                    ? { color: "red" }
                    : {})
                }}
              >
                Tagline:
              </strong>{" "}
              {tutor.profile.tagline}
            </div>
            <div>
              <strong>Location:</strong> {tutor.profile.region},{" "}
              {tutor.profile.country}
            </div>
            <div>
              <strong>Years Teaching:</strong>{" "}
              {tutor.profile.years_teaching_languages}
            </div>
            <div>
              <strong>Languages Spoken:</strong>{" "}
              {tutor.profile.speak_languages &&
                tutor.profile.speak_languages
                  .map((lang) => lang.name)
                  .join(", ")}
            </div>
          </Grid>
          <Grid item xs={12} md={2}>
            <h4>Contact & Payment Details</h4>
            <div>
              <strong>E-mail:</strong> {tutor.email}
            </div>
            <div>
              <strong>Skype:</strong> {tutor.profile.skype}
            </div>
            <div>
              <strong>Mobile:</strong>
              {tutor.profile.mobile && "+" + tutor.profile.mobile}
            </div>
            <div>
              <strong>Landline:</strong>{" "}
              {tutor.profile.landline && "+" + tutor.profile.landline}
            </div>
            <div>
              <strong>Whatsapp:</strong>{" "}
              {tutor.profile.whatsapp && "+" + tutor.profile.whatsapp}
            </div>

            <div>
              <strong>Payment method:</strong> {tutor.profile.payment_method}
            </div>
            {tutor.profile.payment_method === "Paypal" ? (
              <div>
                <strong>Paypal address:</strong> {tutor.profile.paypal_address}
              </div>
            ) : (
              <div>
                <strong>Payment details:</strong>{" "}
                {tutor.profile.payment_details}
              </div>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <h4>Classes details </h4>
            <div>
              <strong>Minimum hours:</strong> {tutor.profile.minimum_hours}{" "}
              <br />
              <br />
            </div>
            <Grid container className={classes.root} spacing={2}>
              {tutor.profile.languages &&
                tutor.profile.languages.map((lang) => (
                  <Grid item xs={12} md={4} key={lang.name}>
                    <div>
                      <strong>Language:</strong> {lang.name}
                    </div>
                    <div>
                      <strong>Types of course:</strong>{" "}
                      {lang.types_of_course.map((type) => type.name).join(", ")}
                    </div>
                    <div>
                      <strong>Price:</strong> ${lang.price}
                    </div>
                    <div>
                      <strong>Price Trial:</strong> ${lang.price_trial}
                    </div>
                    <div>
                      <strong>Max Students:</strong> {lang.max_students}
                    </div>
                    <div>
                      <strong>Price extra student:</strong> $
                      {lang.price_extra_student}
                    </div>
                  </Grid>
                ))}
            </Grid>
          </Grid>
          <Grid item xs={12} md={2}>
            <h4
              style={{
                ...(tutor.profile.formChanged &&
                tutor.profile.formChanged.indexOf("picture") !== -1
                  ? { color: "red" }
                  : {})
              }}
            >
              Profile Picture{" "}
            </h4>
            <img
              style={{
                border: "#ccc solid 1px",
                objectFit: "cover",
                objectPosition: "center",
                height: "200px",
                width: "200px",
                minWidth: "200px"
              }}
              src={tutor.profile.picture}
              alt="My Profile"
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <h4
              style={{
                ...(tutor.profile.formChanged &&
                tutor.profile.formChanged.indexOf("video") !== -1
                  ? { color: "red" }
                  : {})
              }}
            >
              Profile Video{" "}
            </h4>
            <video
              id="videoPlayerOld"
              style={{
                border: "#ccc solid 1px",
                objectFit: "cover",
                objectPosition: "center",
                width: "100%"
              }}
              autoPlay={false}
              src={tutor.profile.video}
              controls={true}
              playsInline
            ></video>
          </Grid>
          <Grid item xs={12} md={5}>
            <h4>Qualifications and Profile </h4>
            <div>
              <strong
                style={{
                  ...(tutor.profile.formChanged &&
                  tutor.profile.formChanged.indexOf("work_experience") !== -1
                    ? { color: "red" }
                    : {})
                }}
              >
                Work Experience:
              </strong>{" "}
              {tutor.profile.work_experience}
            </div>
            <div>
              <strong
                style={{
                  ...(tutor.profile.formChanged &&
                  tutor.profile.formChanged.indexOf("qualifications") !== -1
                    ? { color: "red" }
                    : {})
                }}
              >
                Qualifications:
              </strong>{" "}
              <ul>
                {tutor.profile.qualifications &&
                  Array.isArray(tutor.profile.qualifications) &&
                  tutor.profile.qualifications.map((qa) => (
                    <li>{qa.description}</li>
                  ))}
              </ul>
            </div>
            <div>
              <strong
                style={{
                  ...(tutor.profile.formChanged &&
                  tutor.profile.formChanged.indexOf("description") !== -1
                    ? { color: "red" }
                    : {})
                }}
              >
                Profile:
              </strong>{" "}
              {tutor.profile.description}
            </div>
            <h4>Availability - {tutor.profile.timezone}</h4>
            <AvailabilityTable availability={tutor.profile.availability} />
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650
  },
  tableHead: {
    backgroundColor: "#eee"
  },
  progress: {
    position: "absolute",
    top: "60px",
    right: "20px"
  },
  modalPaper: {
    width: "10%"
  },
  approved: {
    color: theme.palette.green.main
  }
}));

export default TutorView;
