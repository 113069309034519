import axios from "axios";

export const deleteUser = (id) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_USERS", payload: true });
    dispatch({ type: "SET_BYPASS_IO_USERS", payload: true });
    try {
      const responseData = await axios.delete(process.env.REACT_APP_BACKEND_URL + "/users/"+id);
      dispatch({ type: "DELETE_USER", payload: id });
      dispatch({ type: "LOADING_USERS", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_USERS", payload: err });
      dispatch({ type: "SET_BYPASS_IO_USERS", payload: false });
      dispatch({ type: "LOADING_USERS", payload: false });
    }
  };
};

export const setBypassIoUsers = (should) => ({ type: "SET_BYPASS_IO_USERS", payload: should });

export const setOpenUser = (open) => ({ type: "SET_OPEN_USER", payload: open });

export const updateUser = (user) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_USERS", payload: true });
    dispatch({ type: "SET_BYPASS_IO_USERS", payload: true });
    try {
      const responseData = await axios.post(process.env.REACT_APP_BACKEND_URL + "/users", user);
      dispatch({ type: "UPDATE_USER", payload: user });
      dispatch({ type: "LOADING_USERS", payload: false });
      dispatch({ type: "CLEAR_CURRENT_USER" });
    } catch (err) {
      dispatch({ type: "ERROR_USERS", payload: err });
      dispatch({ type: "SET_BYPASS_IO_USERS", payload: false });
      dispatch({ type: "LOADING_USERS", payload: false });
    }
  };
};

export const addUser = (user) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_USERS", payload: true });
    dispatch({ type: "SET_BYPASS_IO_USERS", payload: true });
    try {
      const responseData = await axios.put(process.env.REACT_APP_BACKEND_URL + "/users", user);
      dispatch({ type: "ADD_USER", payload: user });
      dispatch({ type: "LOADING_USERS", payload: false });
      dispatch({ type: "SET_OPEN_USER", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_USERS", payload: err });
      dispatch({ type: "SET_BYPASS_IO_USERS", payload: false });
      dispatch({ type: "LOADING_USERS", payload: false });
    }
  };
};

export const setCurrentUser = (user) => ({ type: "CURRENT_USER", payload: user });

export const clearCurrentUser = () => ({ type: "CLEAR_CURRENT_USER" });

export const clearErrorUsers = () => ({ type: "CLEAR_ERROR_USERS" });

export const getUsers = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING_USERS", payload: true });
    try {
      const responseData = await axios.get(process.env.REACT_APP_BACKEND_URL + "/users");

      dispatch({ type: "GET_USERS", payload: responseData.data.users });
      dispatch({ type: "LOADING_USERS", payload: false });
    } catch (err) {
      dispatch({ type: "ERROR_USERS", payload: err });
      dispatch({ type: "LOADING_USERS", payload: false });
    }
  };
};
