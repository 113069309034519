const initialState = {
  all: null,
  current: null,
  filtered: null,
  filter: null,
  error: null,
  loading: true,
  bypassIo: false,
  open: false
};

const getFiltered = (tutors) => {
  const filters = ["Blocked", "Approved", "Rejected", "Draft", "Pending"];
  let filtered = {};
  let filterFn;
  filters.forEach((filter) => {
    if (filter === "Blocked") filterFn = (tutor) => tutor.blocked === true;
    else if (filter === "Approved")
      filterFn = (tutor) =>
        tutor.blocked !== true &&
        tutor.profile.approved === 1 &&
        tutor.profile.isDraft === false;
    else if (filter === "Rejected")
      filterFn = (tutor) =>
        tutor.blocked !== true &&
        tutor.profile.approved === -1 &&
        tutor.profile.isDraft === false;
    else if (filter === "Draft")
      filterFn = (tutor) =>
        tutor.blocked !== true && tutor.profile.isDraft === true;
    else if (filter === "Pending")
      filterFn = (tutor) =>
        tutor.blocked !== true &&
        tutor.profile.isDraft === false &&
        (!tutor.profile.approved || tutor.profile.approved === 0);

    filtered[filter] = tutors.filter(filterFn);
  });
  return filtered;
};

const tutors = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_TUTOR": {
      if (action.payload.tutor_id)
        action.payload = {
          ...action.payload.user,
          _id: action.payload._id,
          profile: action.payload
        };
      return { ...state, all: [...state.all, action.payload] };
    }
    case "DELETE_TUTOR":
      return {
        ...state,
        all: state.all.filter((tutor) => tutor._id !== action.payload)
      };
    case "UPDATE_TUTOR": {
      if (action.payload.tutor_id)
        action.payload = {
          ...action.payload.user,
          _id: action.payload._id,
          profile: action.payload
        };
      let updated = state.all.map((tutor) =>
        tutor._id !== action.payload._id ? tutor : action.payload
      );
      return {
        ...state,
        all: updated,
        filtered: getFiltered(updated)
      };
    }
    case "GET_TUTORS": {
      return {
        ...state,
        all: action.payload,
        filtered: getFiltered(action.payload)
      };
    }
    case "CURRENT_TUTOR":
      return { ...state, current: action.payload };
    case "CLEAR_CURRENT_TUTOR":
      return { ...state, current: null };
    case "SET_BYPASS_IO_TUTORS":
      return { ...state, bypassIo: action.payload };
    case "SET_OPEN_TUTOR":
      return { ...state, open: action.payload };
    case "ERROR_TUTORS":
      let tmpError = action.payload;
      try {
        tmpError = JSON.parse(tmpError.response.data.message);
        tmpError.list = tmpError.list
          ? "<ul>" +
            tmpError.list.map((error) => "<li>" + error + "</li>").join("") +
            "</ul>"
          : "";
      } catch (err) {
        tmpError = {
          msg: tmpError.response
            ? tmpError.response.data.message
            : tmpError.toString(),
          list: ""
        };
      }

      return { ...state, error: tmpError };
    case "FILTER_TUTORS":
      return {
        ...state,
        filter: action.payload
      };

    case "CLEAR_ERROR_TUTORS":
      return { ...state, error: null };
    case "LOADING_TUTORS":
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

export default tutors;
